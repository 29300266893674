<template>
   <ul>
        <accordionItem v-for="( item, index ) in list" :key="index" 
        :title="item.title" :summary="item.summary" :opened="item.opened" :img="item.img"
        :index="index"
        />
   </ul>
</template>
<script setup>
import accordionItem from './accordionItem.vue';
import { accordion } from '../scripts/accordion';
import { onMounted, defineProps } from 'vue'

defineProps({
    list: {
        type: Array,
        required: true
    }
})

onMounted(() => {
  accordion()
})
</script>
<style scoped>
    @import url('../styles/divulgation.css');
</style>