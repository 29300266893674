export const accordion = () => {
    const accordionItems = Array.from(document.querySelectorAll('.divulgation a'));

    accordionItems.forEach(accordion => {
        accordion.addEventListener('click', function () {
            const accordionContent = this.nextElementSibling

            if ( this.classList.contains('accordion-item-desktop') && 
            accordionContent && accordionContent.tagName === "A" || !accordionContent 
            ) {
                checkIfItIsOpenAccordionDesktop();
                this.classList.add('accordion-item-opened')
                return
            }

            if (!accordionContent.classList.contains('accordion-item-content-opened')) {   
                checkIfItIsOpenAccordionMobile();

                this.classList.add('accordion-item-opened')
                accordionContent.classList.add('accordion-item-content-opened')
                // accordionContent.scrollIntoView({ behavior: 'smooth' });
            } else {
                this.classList.remove('accordion-item-opened')
                accordionContent.classList.remove('accordion-item-content-opened')
            }
        })
    })
}

const checkIfItIsOpenAccordionMobile = () => {
    const accordionOpened = document.querySelector('.accordion-item .accordion-item-opened')

    if ( accordionOpened ) {
        const accordionContent = accordionOpened.nextElementSibling;

        accordionOpened.classList.remove('accordion-item-opened')
        accordionContent.classList.remove('accordion-item-content-opened')
    }
}


const checkIfItIsOpenAccordionDesktop = () => {
    const accordionOpened = document.querySelector('.list-desktop a.accordion-item-opened')

    if ( accordionOpened ) {
        accordionOpened.classList.remove('accordion-item-opened')
    }
}