<template>
  <li class="accordion-item">
    <a :index="index" 
    class="item-link item-content" :class="opened ? 'accordion-item-opened' : ''">
      {{ props.title }}
    </a>
    <div class="accordion-item-content" :class="opened ? 'accordion-item-content-opened' : ''">
      <div class="lt-sm">
        <h4>{{ props.title }}</h4>
        <p v-html="props.summary"/>
      </div>
    </div>
  </li>
</template>
<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  summary: {
    type: String,
    required: true
  },
  img: {
    type: String,
  },
  opened: {
    type: Boolean
  },
  index: {
    type: Number,
    required: true
  }
})

</script>
<style scoped>
@import url('../styles/divulgation.css');

.accordion-item-content > div {
  transition-duration: 450ms;
  height: 0px;
  padding: 0px 2.5rem;
  border-radius: 8px;
  overflow: hidden;
}

.accordion-item-content > div {
  border: 0px solid #F2F3F4;
}

.accordion-item-content.accordion-item-content-opened > div {
  border: 1px solid #F2F3F4;
  height: auto;
  margin-top: 16px;
  padding: 1.8rem 2.5rem;
}


@media ( min-width: 599.99px ) {
  .accordion-item {
    display: flex;
    position: relative;
  }

  .accordion-item a {
    min-width: 33.33%;
    max-width: 330px;
  }
}


</style>